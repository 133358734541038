import React from "react";
import { Row } from "antd";
import ReactTypingEffect from "react-typing-effect";

// const { Title } = Typography;

export default function Homepage() {
  const text = [
    "My name is Long Bui",
    "I'm a Web Developer",
    // "I like NodeJS",
    "Here is a lazy sloth's place...",
    // "a funny boy ԅ(≖‿≖ԅ)",
  ];

  return (
    <div className="homepage">
      <Row className="stage" align="middle">
        <div>
          <p className="static-text">Hello.</p>
          <p className="typing-text">
            <ReactTypingEffect
              text={text}
              // className={}
              cursor={"_"}
              // cursorClassName={}
              speed={200}
              eraseSpeed={50}
              typingDelay={100}
              // staticText={"I’m"}
              eraseDelay={2500}
            />
          </p>
        </div>
      </Row>
    </div>
  );
}
