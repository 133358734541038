import React from "react";

import { Timeline } from "antd";
import { ITimeline } from "interfaces/Routes";

import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

dayjs.extend(customParseFormat);

const sample: ITimeline[] = [
  {
    from: "Jun 2020",
    to: "Sep 2021",
    title: "Full-stack Developer - NodeJS/ReactJS",
    content:
      "Working directly with Cloud Mile (Singapore)\n" +
      "Work as a dedicated developer on 4 projects that support the healthcare industry such as vaccine, patient management, appointment scheduling, event planning, and guest management, etc.\n" +
      "\n" +
      "SwiftVax\n" +
      "The system directly manages the number of vaccines, injection history, appointment scheduling, SMS notification to the persons with strict control from the order of injection to the compatibility of that person for the vaccine.\n" +
      "\n" +
      "- Team size: 5\n" +
      "- Technologies:\n" +
      "• Back-end: TypeScript, ExpressJS, Knex\n" +
      "• Front-end: ReactJS, TypeScript, Ant Design\n" +
      "• Database: MySQL\n" +
      "• Third-parties: Google OAuth2, Telegram, Twillio\n" +
      "\n" +
      "GovEntry\n" +
      "The system controls citizens' entry/exit history at buildings, events by QR Codes and answering questions in medical declarations depends on different places. \n" +
      "\n" +
      "- Team size: 2\n" +
      "- Technologies:\n" +
      "• Back-end: TypeScript, ExpressJS, Knex, SocketIO\n" +
      "• Front-end: ReactJS, TypeScript, Ant Design\n" +
      "• Database: MySQL\n" +
      "• Third-parties: Google OAuth2\n" +
      "\n" +
      "SwiftMed & SwiftTest\n" +
      "The system to manage patient information, test results, diagnosis from doctors at medical centers in the system\n" +
      "\n" +
      "- Team size: 3\n" +
      "- Technologies:\n" +
      "• Back-end: TypeScript, ExpressJS, Knex\n" +
      "• Front-end: ReactJS, TypeScript, Ant Design\n" +
      "• Database: MySQL\n" +
      "• Third-parties: Google OAuth2",
  },
  {
    from: "Jun 2019",
    to: "Jun 2020",
    title: "Front-end Developer - ReactJS",
    content:
      "User application for H3Zoom.AI (Singapore)\n" +
      "A system analyzes the huge amount of data collected and renders them in 3D motion, providing users with the vital information they need such as stains, cracks, rust, etc. in relation to the condition of the building's facilities.\n" +
      "\n" +
      "- Team size: 4\n" +
      "- Technologies: ReactJS, Chart.js, Redux, React Context, Bootstrap, jQuery, SASS, etc.\n" +
      "\n" +
      "Back-office webs for Prox-i (French Polynesia)\n" +
      "Air Tahiti Nui\n" +
      "Integrating all important and helpful features into the product to let them purchase tickets, plan their itineraries, prepare for the journeys and manage their past flights with rigorous security and stability to protect the integrity of the customer data and the organizational operation.\n" +
      "\n" +
      "- Team size: 8+\n" +
      "- Technologies: ReactJS, Webpack, NextJS, React Context, Ant Design, Draft.js, SCSS, LESS, Gulp.js, Pug, etc.\n" +
      "\n" +
      "Pass Tama'a\n" +
      "Traditionally, at French Polynesia, lunch vouchers were handed out to employees as physical tickets. This system helps companies digitize the distribution of meal vouchers to their employees, allows employees to pay with smartphone or tablet for all or part of their meal at restaurants linked to the Pass Tama'a network.\n" +
      "\n" +
      "- Team size: 9+\n" +
      "- Technologies: ReactJS, Webpack, NextJS, Redux Saga, Ant Design, Chart.js, LESS,Lodash, etc.",
  },
  {
    from: "Jan 2018",
    to: "Jun 2019",
    title: "Back-end Developer - NodeJS",
    content:
      "• Advosights\n" +
      "Advosights is a one-stop solution for scalable and sustainable advocacy marketing. We provide brand professionals with" +
      "an innovative and insights-driven way to execute successful advocacy marketing strategies from Top Influencer, Top Content Creators Top," +
      "Top Amplifier Factor, Top Passion Score, etc. on social networks.\n" +
      "\n" +
      "- Team size: 12+\n" +
      "- Technologies:\n" +
      "• Back-end: NodeJS, FeathersJS, ExpressJS, Mongoose, Google Extensions, etc.\n" +
      "• Front-end: HTML/CSS/JS\n" +
      "• Database: MongoDB",
  },
  {
    from: "Jul 2017",
    to: null,
    title: "Back-end Developer Internship/Partner",
    content:
      "- Using the tools to collect data from the social network Facebook\n" +
      "- Summarize crawled data in the database (MongoDB) and generate reports\n" +
      "- Support to design wireframe, web, slide, sheet, etc.",
  },
  {
    from: "Oct 2014",
    to: null,
    title: "Student's life",
    content: "Ho Chi Minh City Open University\nMajor: Computer Science",
  },
];

export default function TimelinePage() {
  return (
    <div className="timeline">
      <p className="title-lv2">Timeline</p>
      <Timeline mode="left">
        <Timeline.Item dot={<></>}></Timeline.Item>
        {sample
          .sort(
            (a: ITimeline, b: ITimeline) =>
              dayjs(b.from).unix() - dayjs(a.from).unix()
          )
          .map((e: ITimeline, i: number) => {
            return (
              <Timeline.Item
                label={
                  <>
                    <span>{dayjs(e.from, "MMM YYYY").format("MMM YYYY")}</span>
                    {e.to ? (
                      <span>{dayjs(e.to, "MMM YYYY").format("MMM YYYY")}</span>
                    ) : (
                      i === 0 && <span>Current</span>
                    )}
                  </>
                }
              >
                <p>{e.title}</p>
                <p>
                  {e.content &&
                    e.content.split("\n").map((c: string) => (
                      <>
                        {c}
                        <br />
                      </>
                    ))}
                </p>
              </Timeline.Item>
            );
          })}
      </Timeline>
    </div>
  );
}
