import React, {
  useState,
  useEffect,
  //  Suspense,
  //  lazy
} from "react";
import { Link, useHistory } from "react-router-dom";
import { Layout, Menu, Row, Col, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
// import { ReactComponent as LogoLB } from "assets/svg/lb-bold.svg";

import usePublicRoutes from "routes/PublicRoutes";
// import Meta from "antd/lib/card/Meta";

// const Contact = lazy(() => import("components/Contact"));
const { Header, Content, Footer } = Layout;

export default function HeaderContentFooter(props: any) {
  const [isBorderVisible, setIsBorderVisible] = useState(false);
  const publicRoutes = usePublicRoutes();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    // Define a function that is called when the scroll event fires
    const handleScroll = (e: any) => {
      const scrollTop = e.target.documentElement.scrollTop;
      if (scrollTop > 1) {
        setIsBorderVisible(true);
      } else {
        setIsBorderVisible(false);
      }
    };

    // Add the event listener inside a useEffect
    if (document) {
      document.addEventListener("scroll", handleScroll);
    }

    // Remove the event listener on unmount
    return () => {
      if (document) {
        document.removeEventListener("scroll", handleScroll);
      }
    };
  }, [setIsBorderVisible]);

  const MenuItems = publicRoutes.map((r) => (
    <Menu.Item key={r.path} onClick={onClose}>
      {history.location.pathname !== r.path ? (
        <Link to={r.path}>{r.title}</Link>
      ) : (
        r.title
      )}
    </Menu.Item>
  ));

  return (
    <Layout className="hcf-layout">
      <Header className={`hcf-header ${isBorderVisible ? "scrolled" : ""}`}>
        <div>
          <Row className="hcf-expander" justify="end" align="middle">
            <Col>
              <Button type="link" onClick={showDrawer} style={{ padding: 0 }}>
                <MenuOutlined style={{ fontSize: 20 }} />
              </Button>
            </Col>
          </Row>
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <Menu
              className="hcf-menu"
              mode="vertical"
              defaultSelectedKeys={[history.location.pathname]}
            >
              {MenuItems}
            </Menu>
          </Drawer>
          {publicRoutes.length && (
            <Menu
              className="hcf-menu"
              mode="horizontal"
              defaultSelectedKeys={[history.location.pathname]}
            >
              {MenuItems}
            </Menu>
          )}
        </div>
      </Header>
      <Content className="hcf-conntent">
        <div>{props.children}</div>
      </Content>
      <Footer className="hcf-footer">
        <div>
          {/* <Divider />
          <Suspense fallback={<div>Loading...</div>}>
            <Contact />
          </Suspense>
          <Divider />
          Made with ❤ by Long Bui */}
        </div>
      </Footer>
    </Layout>
  );
}
