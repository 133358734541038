import {
  // Descriptions,
  Divider,
  Image,
  Row,
  Tooltip,
} from "antd";

import NodeJsLogo from "assets/png/skills/languages/Nodejs-logo.png";
import TypeScriptLogo from "assets/png/skills/languages/typescript.png";

import ExpressJsLogo from "assets/png/skills/backend/ExpressJS_logo.png";
import FeatherJSLogo from "assets/png/skills/backend/featherjs_logo.png";
import KnexLogo from "assets/png/skills/backend/knex.png";
import MongooseLogo from "assets/png/skills/backend/mongoose_logo.png";
import NestJSLogo from "assets/png/skills/backend/nestjs.png";
import PuppeteerLogo from "assets/png/skills/backend/puppeteer.png";
import SocketIOLogo from "assets/png/skills/backend/socketio_logo.png";
import TwilioLogo from "assets/png/skills/backend/twilio_logo.png";
import TypeORMLogo from "assets/png/skills/backend/typeorm.png";

import ReactJS from "assets/png/skills/frontend/Reactjs.png";
import AntDesignLogo from "assets/png/skills/frontend/AntDesign.png";
import BootstrapLogo from "assets/png/skills/frontend/bootstrap-logo.png";
import ChartJSLogo from "assets/png/skills/frontend/chartjs-logo.png";
import FluentUI from "assets/png/skills/frontend/fluent_ui.png";

import MySQLLogo from "assets/png/skills/database/MySQL-Logo.png";
import MongoDBLogo from "assets/png/skills/database/MongoDB_Logo.png";

import FigmaLogo from "assets/png/skills/design/logo-figma.png";
import IllustratorLogo from "assets/png/skills/design/adobe_illustrator_logo.png";
import DrawIOLogo from "assets/png/skills/design/DrawIO.png";

import SwaggerLogo from "assets/png/skills/others/swagger_logo.png";
import DockerLogo from "assets/png/skills/others/Docker_logo.png";

export default function Technologies() {
  return (
    <div className="technologies">
      <p className="title-lv2">Technologies</p>
      <Divider>Languages</Divider>
      <Row justify="center">
        <Tooltip placement="top" title={"NodeJS"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={NodeJsLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"TypeScript"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={TypeScriptLogo}
            preview={false}
          />
        </Tooltip>
      </Row>
      <Divider>Back-end</Divider>
      <Row justify="center">
        <Tooltip placement="top" title={"ExpressJS"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={ExpressJsLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"FeatherJS"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={FeatherJSLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Knex"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={KnexLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Mongoose"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={MongooseLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"NestJS"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={NestJSLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Puppeteer"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={PuppeteerLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Socket.IO"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={SocketIOLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Twilio"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={TwilioLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"TypeORM"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={TypeORMLogo}
            preview={false}
          />
        </Tooltip>
      </Row>
      <Divider>Front-end</Divider>
      <Row justify="center">
        <Tooltip placement="top" title={"ReactJS"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={ReactJS}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Ant Design"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={AntDesignLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Bootstrap"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={BootstrapLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"ChartJS"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={ChartJSLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Fluent UI"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={FluentUI}
            preview={false}
          />
        </Tooltip>
      </Row>
      <Divider>Database</Divider>
      <Row justify="center">
        <Tooltip placement="top" title={"MySQL"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={MySQLLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"MongoDB"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={MongoDBLogo}
            preview={false}
          />
        </Tooltip>
      </Row>
      <Divider>Design</Divider>
      <Row justify="center">
        <Tooltip placement="top" title={"Figma"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={FigmaLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Adobe Illustrator"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={IllustratorLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Draw.IO"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={DrawIOLogo}
            preview={false}
          />
        </Tooltip>
      </Row>
      <Divider>Others</Divider>
      <Row justify="center">
        <Tooltip placement="top" title={"Swagger"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={SwaggerLogo}
            preview={false}
          />
        </Tooltip>
        <Tooltip placement="top" title={"Docker"}>
          <Image
            loading={"lazy"}
            className={"skill-logo"}
            src={DockerLogo}
            preview={false}
          />
        </Tooltip>
      </Row>

      {/* <Descriptions>
        <Descriptions.Item span={24} label="Languages">
          NodeJS, TypeScript
        </Descriptions.Item>
        <Descriptions.Item span={24} label="Back-end">
          ExpressJS, FeatherJS, NestJS, Strapi, Knex, TypeORM, Sequelize,
          Mongoose,Puppeteer, SocketIO, Twilio, Telegram, JWT, OAuth, XLSX
        </Descriptions.Item>
        <Descriptions.Item span={24} label="Front-end">
          ReactJS, Ant Design, Bootstrap, Context, React Hooks, HOC,
          ChartJS,Google Chart, FluentUI, MaterialUI
        </Descriptions.Item>
        <Descriptions.Item span={24} label="Database">
          MySQL, MongoDB
        </Descriptions.Item>
        <Descriptions.Item span={24} label="Design">
          Figma, Adobe Illustrator, Draw.io
        </Descriptions.Item>
        <Descriptions.Item span={24} label="Others">
          Swagger, Docker
        </Descriptions.Item>
      </Descriptions> */}
    </div>
  );
}
