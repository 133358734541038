// Interfaces
import { IRoute } from "interfaces/Routes";

// Pages
import Homepage from "pages/Homepage";
import Technologies from "pages/Technologies";
import Timeline from "pages/Timeline";

// Icons
// import {ReactComponent as HomepageIcon} from "../icons/HomepageIcon.svg"

export default function usePublicRoutes() {
  const publicRoutesList: IRoute[] = [
    {
      title: "Homepage",
      path: "/",
      exact: true,
      page: Homepage,
    },
    {
      title: "Technologies",
      path: "/technologies",
      exact: true,
      page: Technologies,
    },
    {
      title: "Timeline",
      path: "/timeline",
      exact: true,
      page: Timeline,
    },
  ];

  return publicRoutesList;
}
