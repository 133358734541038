import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HeaderContentFooter from "layouts/HeaderContentFooter";

import usePublicRoutes from "./PublicRoutes";

export default function Routes() {
  const publicRoutes = usePublicRoutes();
  return (
    <Router>
      <Switch>
        <HeaderContentFooter>
          {publicRoutes.map((r) => {
            return (
              <div className="container">
                <Route
                  path={r.path}
                  exact={r.exact}
                  component={r.page}
                  key={JSON.stringify(r)}
                />
              </div>
            );
          })}
        </HeaderContentFooter>
      </Switch>
    </Router>
  );
}
